/**
 * Member Management Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AdditionalMemberIntegrationModel } from '../model/additionalMemberIntegrationModel';
// @ts-ignore
import { ProcessType } from '../model/processType';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface TestCreateJoinNowMemberPostRequestParams {
    clubId?: string;
    clubBusinessId?: string;
    abcMemberId?: string;
    abcMemberFirstName?: string;
    abcMemberLastName?: string;
    abcMemberEmail?: string;
    abcMemberPhone?: string;
    agreementNumber?: string;
    referringMemberId?: string;
    createdAt?: string;
    additionalMembers?: Array<AdditionalMemberIntegrationModel>;
    id?: string;
    eventDate?: string;
    correlationId?: string;
    recipient?: string;
    scheduledAt?: string | null;
    clientName?: string;
    processType?: ProcessType;
}


@Injectable({
  providedIn: 'root'
})
export class TestService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public testCreateJoinNowMemberPost(requestParameters?: TestCreateJoinNowMemberPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public testCreateJoinNowMemberPost(requestParameters?: TestCreateJoinNowMemberPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public testCreateJoinNowMemberPost(requestParameters?: TestCreateJoinNowMemberPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public testCreateJoinNowMemberPost(requestParameters?: TestCreateJoinNowMemberPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        const clubId = requestParameters?.clubId;
        const clubBusinessId = requestParameters?.clubBusinessId;
        const abcMemberId = requestParameters?.abcMemberId;
        const abcMemberFirstName = requestParameters?.abcMemberFirstName;
        const abcMemberLastName = requestParameters?.abcMemberLastName;
        const abcMemberEmail = requestParameters?.abcMemberEmail;
        const abcMemberPhone = requestParameters?.abcMemberPhone;
        const agreementNumber = requestParameters?.agreementNumber;
        const referringMemberId = requestParameters?.referringMemberId;
        const createdAt = requestParameters?.createdAt;
        const additionalMembers = requestParameters?.additionalMembers;
        const id = requestParameters?.id;
        const eventDate = requestParameters?.eventDate;
        const correlationId = requestParameters?.correlationId;
        const recipient = requestParameters?.recipient;
        const scheduledAt = requestParameters?.scheduledAt;
        const clientName = requestParameters?.clientName;
        const processType = requestParameters?.processType;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (clubId !== undefined && clubId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>clubId, 'ClubId');
        }
        if (clubBusinessId !== undefined && clubBusinessId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>clubBusinessId, 'ClubBusinessId');
        }
        if (abcMemberId !== undefined && abcMemberId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>abcMemberId, 'AbcMemberId');
        }
        if (abcMemberFirstName !== undefined && abcMemberFirstName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>abcMemberFirstName, 'AbcMemberFirstName');
        }
        if (abcMemberLastName !== undefined && abcMemberLastName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>abcMemberLastName, 'AbcMemberLastName');
        }
        if (abcMemberEmail !== undefined && abcMemberEmail !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>abcMemberEmail, 'AbcMemberEmail');
        }
        if (abcMemberPhone !== undefined && abcMemberPhone !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>abcMemberPhone, 'AbcMemberPhone');
        }
        if (agreementNumber !== undefined && agreementNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>agreementNumber, 'AgreementNumber');
        }
        if (referringMemberId !== undefined && referringMemberId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>referringMemberId, 'ReferringMemberId');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'CreatedAt');
        }
        if (additionalMembers) {
            additionalMembers.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'AdditionalMembers');
            })
        }
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'Id');
        }
        if (eventDate !== undefined && eventDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>eventDate, 'EventDate');
        }
        if (correlationId !== undefined && correlationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>correlationId, 'CorrelationId');
        }
        if (recipient !== undefined && recipient !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>recipient, 'Recipient');
        }
        if (scheduledAt !== undefined && scheduledAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>scheduledAt, 'ScheduledAt');
        }
        if (clientName !== undefined && clientName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>clientName, 'ClientName');
        }
        if (processType !== undefined && processType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>processType, 'processType');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/test/create-join-now-member`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
