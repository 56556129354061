import type { AnimationTriggerMetadata } from "@angular/animations";
import { animate, style, transition, trigger } from "@angular/animations";

/**
 * Angular shrink horizontally animation
 * @type {AnimationTriggerMetadata}
 */
export const shrinkHorizontallyAnimation: AnimationTriggerMetadata = trigger("shrinkHorizontally", [
    transition(
        ":leave",
        [
            style({
                width: "{{initialWidth}}",
                overflow: "hidden",
            }),
            animate(
                `{{duration}}ms {{delay}}ms ease-out`,
                style({
                    width: "0px",
                    overflow: "inherit",
                }),
            ),
        ],
        { params: { duration: 200, delay: 0, initialWidth: "*" } },
    ),
]);
