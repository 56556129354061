import type { AnimationTriggerMetadata } from "@angular/animations";
import { animate, style, transition, trigger } from "@angular/animations";

/**
 * Angular grow horizontally animation
 * @type {AnimationTriggerMetadata}
 */
export const growHorizontallyAnimation: AnimationTriggerMetadata = trigger("growHorizontally", [
    transition(
        ":enter",
        [
            style({
                width: "0px",
                overflow: "hidden",
            }),
            animate(
                `{{duration}}ms {{delay}}ms ease-in`,
                style({
                    width: "{{desiredWidth}}",
                    overflow: "inherit",
                }),
            ),
        ],
        { params: { duration: 200, delay: 0, desiredWidth: "*" } },
    ),
]);
