import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({
    providedIn: "root",
})
export class NotificationsService {
    constructor(private messageService: MessageService) {}

    public success(message: string, duration: number = 5000): void {
        this.messageService.add({ summary: message, life: duration, severity: "success" });
    }

    public error(message: string, duration: number = 5000): void {
        this.messageService.add({ summary: message, life: duration, severity: "error" });
    }

    public persistentError(message: string): void {
        this.messageService.add({ summary: message, severity: "error" });
    }

    public info(message: string, duration: number = 5000): void {
        this.messageService.add({ summary: message, life: duration, severity: "info" });
    }
}
